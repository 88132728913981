export const AboutTrakken = () => import('../../components/AboutTrakken.vue' /* webpackChunkName: "components/about-trakken" */).then(c => wrapFunctional(c.default || c))
export const AnnouncmentSection = () => import('../../components/AnnouncmentSection.vue' /* webpackChunkName: "components/announcment-section" */).then(c => wrapFunctional(c.default || c))
export const AsBanner = () => import('../../components/AsBanner.vue' /* webpackChunkName: "components/as-banner" */).then(c => wrapFunctional(c.default || c))
export const AsButton = () => import('../../components/AsButton.vue' /* webpackChunkName: "components/as-button" */).then(c => wrapFunctional(c.default || c))
export const BasicWaves = () => import('../../components/BasicWaves.vue' /* webpackChunkName: "components/basic-waves" */).then(c => wrapFunctional(c.default || c))
export const Benefits = () => import('../../components/Benefits.vue' /* webpackChunkName: "components/benefits" */).then(c => wrapFunctional(c.default || c))
export const BenefitsDa = () => import('../../components/BenefitsDa.vue' /* webpackChunkName: "components/benefits-da" */).then(c => wrapFunctional(c.default || c))
export const BottomFooter = () => import('../../components/BottomFooter.vue' /* webpackChunkName: "components/bottom-footer" */).then(c => wrapFunctional(c.default || c))
export const CardSlider = () => import('../../components/CardSlider.vue' /* webpackChunkName: "components/card-slider" */).then(c => wrapFunctional(c.default || c))
export const CardSliderInsights = () => import('../../components/CardSliderInsights.vue' /* webpackChunkName: "components/card-slider-insights" */).then(c => wrapFunctional(c.default || c))
export const CardSliderInsightsBackup = () => import('../../components/CardSliderInsightsBackup.vue' /* webpackChunkName: "components/card-slider-insights-backup" */).then(c => wrapFunctional(c.default || c))
export const CardsHorizontal = () => import('../../components/CardsHorizontal.vue' /* webpackChunkName: "components/cards-horizontal" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/ContactForm.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const ContactFormEvents = () => import('../../components/ContactFormEvents.vue' /* webpackChunkName: "components/contact-form-events" */).then(c => wrapFunctional(c.default || c))
export const ContactFormEventsCustom = () => import('../../components/ContactFormEventsCustom.vue' /* webpackChunkName: "components/contact-form-events-custom" */).then(c => wrapFunctional(c.default || c))
export const ContactUs = () => import('../../components/ContactUs.vue' /* webpackChunkName: "components/contact-us" */).then(c => wrapFunctional(c.default || c))
export const ContactUsBackup = () => import('../../components/ContactUs_backup.vue' /* webpackChunkName: "components/contact-us-backup" */).then(c => wrapFunctional(c.default || c))
export const Cookie = () => import('../../components/Cookie.vue' /* webpackChunkName: "components/cookie" */).then(c => wrapFunctional(c.default || c))
export const GallerySlider = () => import('../../components/GallerySlider.vue' /* webpackChunkName: "components/gallery-slider" */).then(c => wrapFunctional(c.default || c))
export const InsightsAuthorBadge = () => import('../../components/InsightsAuthorBadge.vue' /* webpackChunkName: "components/insights-author-badge" */).then(c => wrapFunctional(c.default || c))
export const InsightsDownloadSection = () => import('../../components/InsightsDownloadSection.vue' /* webpackChunkName: "components/insights-download-section" */).then(c => wrapFunctional(c.default || c))
export const InsightsVideoSection = () => import('../../components/InsightsVideoSection.vue' /* webpackChunkName: "components/insights-video-section" */).then(c => wrapFunctional(c.default || c))
export const InsightsYoutubeCard = () => import('../../components/InsightsYoutubeCard.vue' /* webpackChunkName: "components/insights-youtube-card" */).then(c => wrapFunctional(c.default || c))
export const LogoWallSlider = () => import('../../components/LogoWallSlider.vue' /* webpackChunkName: "components/logo-wall-slider" */).then(c => wrapFunctional(c.default || c))
export const NewsletterForm = () => import('../../components/NewsletterForm.vue' /* webpackChunkName: "components/newsletter-form" */).then(c => wrapFunctional(c.default || c))
export const NewsletterFormV2 = () => import('../../components/NewsletterFormV2.vue' /* webpackChunkName: "components/newsletter-form-v2" */).then(c => wrapFunctional(c.default || c))
export const Partnership = () => import('../../components/Partnership.vue' /* webpackChunkName: "components/partnership" */).then(c => wrapFunctional(c.default || c))
export const PartnershipCo = () => import('../../components/PartnershipCo.vue' /* webpackChunkName: "components/partnership-co" */).then(c => wrapFunctional(c.default || c))
export const PartnershipDa = () => import('../../components/PartnershipDa.vue' /* webpackChunkName: "components/partnership-da" */).then(c => wrapFunctional(c.default || c))
export const QuotesSlider = () => import('../../components/QuotesSlider.vue' /* webpackChunkName: "components/quotes-slider" */).then(c => wrapFunctional(c.default || c))
export const RelatedCasesSlider = () => import('../../components/RelatedCasesSlider.vue' /* webpackChunkName: "components/related-cases-slider" */).then(c => wrapFunctional(c.default || c))
export const SearchResult = () => import('../../components/SearchResult.vue' /* webpackChunkName: "components/search-result" */).then(c => wrapFunctional(c.default || c))
export const SectionPlaceholderPadding = () => import('../../components/SectionPlaceholderPadding.vue' /* webpackChunkName: "components/section-placeholder-padding" */).then(c => wrapFunctional(c.default || c))
export const SocialsNewsletter = () => import('../../components/SocialsNewsletter.vue' /* webpackChunkName: "components/socials-newsletter" */).then(c => wrapFunctional(c.default || c))
export const StandardHero = () => import('../../components/StandardHero.vue' /* webpackChunkName: "components/standard-hero" */).then(c => wrapFunctional(c.default || c))
export const TopHeader = () => import('../../components/TopHeader.vue' /* webpackChunkName: "components/top-header" */).then(c => wrapFunctional(c.default || c))
export const TrakkenLogo = () => import('../../components/TrakkenLogo.vue' /* webpackChunkName: "components/trakken-logo" */).then(c => wrapFunctional(c.default || c))
export const VideoHero = () => import('../../components/VideoHero.vue' /* webpackChunkName: "components/video-hero" */).then(c => wrapFunctional(c.default || c))
export const BlocksBasicTextImageBlock = () => import('../../components/blocks/BasicTextImageBlock.vue' /* webpackChunkName: "components/blocks-basic-text-image-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksCardsTextBlock = () => import('../../components/blocks/CardsTextBlock.vue' /* webpackChunkName: "components/blocks-cards-text-block" */).then(c => wrapFunctional(c.default || c))
export const BlocksCardsTextBlockBadges = () => import('../../components/blocks/CardsTextBlockBadges.vue' /* webpackChunkName: "components/blocks-cards-text-block-badges" */).then(c => wrapFunctional(c.default || c))
export const BlocksCardsTextBlockFloat = () => import('../../components/blocks/CardsTextBlockFloat.vue' /* webpackChunkName: "components/blocks-cards-text-block-float" */).then(c => wrapFunctional(c.default || c))
export const BlocksSliderNavigation = () => import('../../components/blocks/SliderNavigation.vue' /* webpackChunkName: "components/blocks-slider-navigation" */).then(c => wrapFunctional(c.default || c))
export const BlocksTextImageBlock = () => import('../../components/blocks/TextImageBlock.vue' /* webpackChunkName: "components/blocks-text-image-block" */).then(c => wrapFunctional(c.default || c))
export const CasestudyCaseStudyHero = () => import('../../components/casestudy/CaseStudyHero.vue' /* webpackChunkName: "components/casestudy-case-study-hero" */).then(c => wrapFunctional(c.default || c))
export const CasestudyProductsServices = () => import('../../components/casestudy/ProductsServices.vue' /* webpackChunkName: "components/casestudy-products-services" */).then(c => wrapFunctional(c.default || c))
export const CompanyCaseStudies = () => import('../../components/company/CaseStudies.vue' /* webpackChunkName: "components/company-case-studies" */).then(c => wrapFunctional(c.default || c))
export const CompanyCaseStudiesAi = () => import('../../components/company/CaseStudiesAi.vue' /* webpackChunkName: "components/company-case-studies-ai" */).then(c => wrapFunctional(c.default || c))
export const CompanyManagement = () => import('../../components/company/CompanyManagement.vue' /* webpackChunkName: "components/company-management" */).then(c => wrapFunctional(c.default || c))
export const CompanyMap = () => import('../../components/company/CompanyMap.vue' /* webpackChunkName: "components/company-map" */).then(c => wrapFunctional(c.default || c))
export const CompanyMapControl = () => import('../../components/company/CompanyMapControl.vue' /* webpackChunkName: "components/company-map-control" */).then(c => wrapFunctional(c.default || c))
export const CompanyTeams = () => import('../../components/company/CompanyTeams.vue' /* webpackChunkName: "components/company-teams" */).then(c => wrapFunctional(c.default || c))
export const ContactIntro = () => import('../../components/contact/ContactIntro.vue' /* webpackChunkName: "components/contact-intro" */).then(c => wrapFunctional(c.default || c))
export const ContactMap = () => import('../../components/contact/ContactMap.vue' /* webpackChunkName: "components/contact-map" */).then(c => wrapFunctional(c.default || c))
export const ContactMapRebuild = () => import('../../components/contact/ContactMapRebuild.vue' /* webpackChunkName: "components/contact-map-rebuild" */).then(c => wrapFunctional(c.default || c))
export const CookieSettingsSection = () => import('../../components/cookie/SettingsSection.vue' /* webpackChunkName: "components/cookie-settings-section" */).then(c => wrapFunctional(c.default || c))
export const EventsSpeakers = () => import('../../components/events/Speakers.vue' /* webpackChunkName: "components/events-speakers" */).then(c => wrapFunctional(c.default || c))
export const FooterBottomSocialsBar = () => import('../../components/footer/FooterBottomSocialsBar.vue' /* webpackChunkName: "components/footer-bottom-socials-bar" */).then(c => wrapFunctional(c.default || c))
export const FooterMenuItem = () => import('../../components/footer/FooterMenuItem.vue' /* webpackChunkName: "components/footer-menu-item" */).then(c => wrapFunctional(c.default || c))
export const FooterNewsletter = () => import('../../components/footer/FooterNewsletter.vue' /* webpackChunkName: "components/footer-newsletter" */).then(c => wrapFunctional(c.default || c))
export const HomepageOverviewIntro = () => import('../../components/homepage/HomepageOverviewIntro.vue' /* webpackChunkName: "components/homepage-overview-intro" */).then(c => wrapFunctional(c.default || c))
export const HomepageOverviewPlatforms = () => import('../../components/homepage/HomepageOverviewPlatforms.vue' /* webpackChunkName: "components/homepage-overview-platforms" */).then(c => wrapFunctional(c.default || c))
export const HomepageRelatedCases = () => import('../../components/homepage/HomepageRelatedCases.vue' /* webpackChunkName: "components/homepage-related-cases" */).then(c => wrapFunctional(c.default || c))
export const HomepageSolutions = () => import('../../components/homepage/HomepageSolutions.vue' /* webpackChunkName: "components/homepage-solutions" */).then(c => wrapFunctional(c.default || c))
export const HomepageTools = () => import('../../components/homepage/HomepageTools.vue' /* webpackChunkName: "components/homepage-tools" */).then(c => wrapFunctional(c.default || c))
export const HomepageVerticalSolutions = () => import('../../components/homepage/VerticalSolutions.vue' /* webpackChunkName: "components/homepage-vertical-solutions" */).then(c => wrapFunctional(c.default || c))
export const InsightsTestComponent = () => import('../../components/insights/TestComponent.vue' /* webpackChunkName: "components/insights-test-component" */).then(c => wrapFunctional(c.default || c))
export const JobsContactForm = () => import('../../components/jobs/ContactForm.vue' /* webpackChunkName: "components/jobs-contact-form" */).then(c => wrapFunctional(c.default || c))
export const JobsEventListItem = () => import('../../components/jobs/EventListItem.vue' /* webpackChunkName: "components/jobs-event-list-item" */).then(c => wrapFunctional(c.default || c))
export const JobsEventsFilter = () => import('../../components/jobs/EventsFilter.vue' /* webpackChunkName: "components/jobs-events-filter" */).then(c => wrapFunctional(c.default || c))
export const JobsEventsList = () => import('../../components/jobs/EventsList.vue' /* webpackChunkName: "components/jobs-events-list" */).then(c => wrapFunctional(c.default || c))
export const JobsJobDetails = () => import('../../components/jobs/JobDetails.vue' /* webpackChunkName: "components/jobs-job-details" */).then(c => wrapFunctional(c.default || c))
export const JobsFilter = () => import('../../components/jobs/JobsFilter.vue' /* webpackChunkName: "components/jobs-filter" */).then(c => wrapFunctional(c.default || c))
export const JobsList = () => import('../../components/jobs/JobsList.vue' /* webpackChunkName: "components/jobs-list" */).then(c => wrapFunctional(c.default || c))
export const JobsListItem = () => import('../../components/jobs/JobsListItem.vue' /* webpackChunkName: "components/jobs-list-item" */).then(c => wrapFunctional(c.default || c))
export const MenuLevel2Multi = () => import('../../components/menu_v2/MenuLevel2Multi.vue' /* webpackChunkName: "components/menu-level2-multi" */).then(c => wrapFunctional(c.default || c))
export const MenuLevel2MultiInsights = () => import('../../components/menu_v2/MenuLevel2MultiInsights.vue' /* webpackChunkName: "components/menu-level2-multi-insights" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductGCPTabs = () => import('../../components/products/ProductGCPTabs.vue' /* webpackChunkName: "components/products-product-g-c-p-tabs" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductIntro = () => import('../../components/products/ProductIntro.vue' /* webpackChunkName: "components/products-product-intro" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductRelatedApps = () => import('../../components/products/ProductRelatedApps.vue' /* webpackChunkName: "components/products-product-related-apps" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductSellingPoints = () => import('../../components/products/ProductSellingPoints.vue' /* webpackChunkName: "components/products-product-selling-points" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductTab = () => import('../../components/products/ProductTab.vue' /* webpackChunkName: "components/products-product-tab" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductTabs = () => import('../../components/products/ProductTabs.vue' /* webpackChunkName: "components/products-product-tabs" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductTitle = () => import('../../components/products/ProductTitle.vue' /* webpackChunkName: "components/products-product-title" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductTools = () => import('../../components/products/ProductTools.vue' /* webpackChunkName: "components/products-product-tools" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductToolsRound = () => import('../../components/products/ProductToolsRound.vue' /* webpackChunkName: "components/products-product-tools-round" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductToolsRound10 = () => import('../../components/products/ProductToolsRound10.vue' /* webpackChunkName: "components/products-product-tools-round10" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductToolsRound3 = () => import('../../components/products/ProductToolsRound3.vue' /* webpackChunkName: "components/products-product-tools-round3" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductToolsRound4 = () => import('../../components/products/ProductToolsRound4.vue' /* webpackChunkName: "components/products-product-tools-round4" */).then(c => wrapFunctional(c.default || c))
export const ProductsProductToolsRound5 = () => import('../../components/products/ProductToolsRound5.vue' /* webpackChunkName: "components/products-product-tools-round5" */).then(c => wrapFunctional(c.default || c))
export const ProductsOverviewDetails = () => import('../../components/products/ProductsOverviewDetails.vue' /* webpackChunkName: "components/products-overview-details" */).then(c => wrapFunctional(c.default || c))
export const ProductsOverviewIntro = () => import('../../components/products/ProductsOverviewIntro.vue' /* webpackChunkName: "components/products-overview-intro" */).then(c => wrapFunctional(c.default || c))
export const ServicesServiceTab = () => import('../../components/services/ServiceTab.vue' /* webpackChunkName: "components/services-service-tab" */).then(c => wrapFunctional(c.default || c))
export const ServicesTabs = () => import('../../components/services/ServicesTabs.vue' /* webpackChunkName: "components/services-tabs" */).then(c => wrapFunctional(c.default || c))
export const UiCTACard = () => import('../../components/ui/CTACard.vue' /* webpackChunkName: "components/ui-c-t-a-card" */).then(c => wrapFunctional(c.default || c))
export const UiCardNewsletter = () => import('../../components/ui/CardNewsletter.vue' /* webpackChunkName: "components/ui-card-newsletter" */).then(c => wrapFunctional(c.default || c))
export const UiFollowUs = () => import('../../components/ui/FollowUs.vue' /* webpackChunkName: "components/ui-follow-us" */).then(c => wrapFunctional(c.default || c))
export const UiImageBlock = () => import('../../components/ui/ImageBlock.vue' /* webpackChunkName: "components/ui-image-block" */).then(c => wrapFunctional(c.default || c))
export const UiLanguageSwitcher = () => import('../../components/ui/LanguageSwitcher.vue' /* webpackChunkName: "components/ui-language-switcher" */).then(c => wrapFunctional(c.default || c))
export const UiPillButton = () => import('../../components/ui/PillButton.vue' /* webpackChunkName: "components/ui-pill-button" */).then(c => wrapFunctional(c.default || c))
export const UiPreHead = () => import('../../components/ui/PreHead.vue' /* webpackChunkName: "components/ui-pre-head" */).then(c => wrapFunctional(c.default || c))
export const UiTextBlock = () => import('../../components/ui/TextBlock.vue' /* webpackChunkName: "components/ui-text-block" */).then(c => wrapFunctional(c.default || c))
export const UiTextBlockMoreIcons = () => import('../../components/ui/TextBlockMoreIcons.vue' /* webpackChunkName: "components/ui-text-block-more-icons" */).then(c => wrapFunctional(c.default || c))
export const UiTextBlockMoreIconsBadges = () => import('../../components/ui/TextBlockMoreIconsBadges.vue' /* webpackChunkName: "components/ui-text-block-more-icons-badges" */).then(c => wrapFunctional(c.default || c))
export const UiTextCardsSection = () => import('../../components/ui/TextCardsSection.vue' /* webpackChunkName: "components/ui-text-cards-section" */).then(c => wrapFunctional(c.default || c))
export const UiTrakkenButton = () => import('../../components/ui/TrakkenButton.vue' /* webpackChunkName: "components/ui-trakken-button" */).then(c => wrapFunctional(c.default || c))
export const UiTrakkenButtonNoLink = () => import('../../components/ui/TrakkenButtonNoLink.vue' /* webpackChunkName: "components/ui-trakken-button-no-link" */).then(c => wrapFunctional(c.default || c))
export const UiTrakkenCard = () => import('../../components/ui/TrakkenCard.vue' /* webpackChunkName: "components/ui-trakken-card" */).then(c => wrapFunctional(c.default || c))
export const UiTrakkenCardInsights = () => import('../../components/ui/TrakkenCardInsights.vue' /* webpackChunkName: "components/ui-trakken-card-insights" */).then(c => wrapFunctional(c.default || c))
export const UiTrakkenCardInsightsWrapped = () => import('../../components/ui/TrakkenCardInsightsWrapped.vue' /* webpackChunkName: "components/ui-trakken-card-insights-wrapped" */).then(c => wrapFunctional(c.default || c))
export const UiTrakkenLink = () => import('../../components/ui/TrakkenLink.vue' /* webpackChunkName: "components/ui-trakken-link" */).then(c => wrapFunctional(c.default || c))
export const UiTrakkenLinkLike = () => import('../../components/ui/TrakkenLinkLike.vue' /* webpackChunkName: "components/ui-trakken-link-like" */).then(c => wrapFunctional(c.default || c))
export const UiTrakkenList = () => import('../../components/ui/TrakkenList.vue' /* webpackChunkName: "components/ui-trakken-list" */).then(c => wrapFunctional(c.default || c))
export const UiTrakkenNewsletter = () => import('../../components/ui/TrakkenNewsletter.vue' /* webpackChunkName: "components/ui-trakken-newsletter" */).then(c => wrapFunctional(c.default || c))
export const UiTrakkenSelect = () => import('../../components/ui/TrakkenSelect.vue' /* webpackChunkName: "components/ui-trakken-select" */).then(c => wrapFunctional(c.default || c))
export const UiTrakkenSubmit = () => import('../../components/ui/TrakkenSubmit.vue' /* webpackChunkName: "components/ui-trakken-submit" */).then(c => wrapFunctional(c.default || c))
export const UiVerticalSolutionBlock = () => import('../../components/ui/VerticalSolutionBlock.vue' /* webpackChunkName: "components/ui-vertical-solution-block" */).then(c => wrapFunctional(c.default || c))
export const InsightsDropdownDropDownFilter = () => import('../../components/insights/dropdown/DropDownFilter.vue' /* webpackChunkName: "components/insights-dropdown-drop-down-filter" */).then(c => wrapFunctional(c.default || c))
export const InsightsDropdownDropDownList = () => import('../../components/insights/dropdown/DropDownList.vue' /* webpackChunkName: "components/insights-dropdown-drop-down-list" */).then(c => wrapFunctional(c.default || c))
export const InsightsDropdownDropDownListItem = () => import('../../components/insights/dropdown/DropDownListItem.vue' /* webpackChunkName: "components/insights-dropdown-drop-down-list-item" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
