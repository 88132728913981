import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _332ef7c0 = () => interopDefault(import('../pages/company/index.vue' /* webpackChunkName: "pages/company/index" */))
const _3a1872fd = () => interopDefault(import('../pages/data-privacy-policy/index.vue' /* webpackChunkName: "pages/data-privacy-policy/index" */))
const _9de2a9a8 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _63e021f1 = () => interopDefault(import('../pages/gcp-terms/index.vue' /* webpackChunkName: "pages/gcp-terms/index" */))
const _642e84ac = () => interopDefault(import('../pages/imprint/index.vue' /* webpackChunkName: "pages/imprint/index" */))
const _c0a36d24 = () => interopDefault(import('../pages/insights/index.vue' /* webpackChunkName: "pages/insights/index" */))
const _058449f6 = () => interopDefault(import('../pages/products-tools/index.vue' /* webpackChunkName: "pages/products-tools/index" */))
const _be0e2204 = () => interopDefault(import('../pages/services-solutions/index.vue' /* webpackChunkName: "pages/services-solutions/index" */))
const _56414d60 = () => interopDefault(import('../pages/why-ai/index.vue' /* webpackChunkName: "pages/why-ai/index" */))
const _49db6ec1 = () => interopDefault(import('../pages/products-tools/google-cloud/index.vue' /* webpackChunkName: "pages/products-tools/google-cloud/index" */))
const _3b6832c4 = () => interopDefault(import('../pages/products-tools/google-marketing-platform/index.vue' /* webpackChunkName: "pages/products-tools/google-marketing-platform/index" */))
const _0770111a = () => interopDefault(import('../pages/services-solutions/conversion-optimization/index.vue' /* webpackChunkName: "pages/services-solutions/conversion-optimization/index" */))
const _42a04380 = () => interopDefault(import('../pages/services-solutions/digital-analytics/index.vue' /* webpackChunkName: "pages/services-solutions/digital-analytics/index" */))
const _0f35953c = () => interopDefault(import('../pages/products-tools/google-cloud/_slug/index.vue' /* webpackChunkName: "pages/products-tools/google-cloud/_slug/index" */))
const _e85bea02 = () => interopDefault(import('../pages/products-tools/google-marketing-platform/_slug/index.vue' /* webpackChunkName: "pages/products-tools/google-marketing-platform/_slug/index" */))
const _5831506e = () => interopDefault(import('../pages/services-solutions/conversion-optimization/_slug/index.vue' /* webpackChunkName: "pages/services-solutions/conversion-optimization/_slug/index" */))
const _f45cc28a = () => interopDefault(import('../pages/services-solutions/digital-analytics/_slug/index.vue' /* webpackChunkName: "pages/services-solutions/digital-analytics/_slug/index" */))
const _23585c21 = () => interopDefault(import('../pages/cases/_slug/index.vue' /* webpackChunkName: "pages/cases/_slug/index" */))
const _16469567 = () => interopDefault(import('../pages/events/_slug/index.vue' /* webpackChunkName: "pages/events/_slug/index" */))
const _0d92fa29 = () => interopDefault(import('../pages/insights/_slug/index.vue' /* webpackChunkName: "pages/insights/_slug/index" */))
const _d5e1988e = () => interopDefault(import('../pages/services-solutions/_slug/index.vue' /* webpackChunkName: "pages/services-solutions/_slug/index" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/company",
    component: _332ef7c0,
    name: "company"
  }, {
    path: "/data-privacy-policy",
    component: _3a1872fd,
    name: "data-privacy-policy"
  }, {
    path: "/events",
    component: _9de2a9a8,
    name: "events"
  }, {
    path: "/gcp-terms",
    component: _63e021f1,
    name: "gcp-terms"
  }, {
    path: "/imprint",
    component: _642e84ac,
    name: "imprint"
  }, {
    path: "/insights",
    component: _c0a36d24,
    name: "insights"
  }, {
    path: "/products-tools",
    component: _058449f6,
    name: "products-tools"
  }, {
    path: "/services-solutions",
    component: _be0e2204,
    name: "services-solutions"
  }, {
    path: "/why-ai",
    component: _56414d60,
    name: "why-ai"
  }, {
    path: "/products-tools/google-cloud",
    component: _49db6ec1,
    name: "products-tools-google-cloud"
  }, {
    path: "/products-tools/google-marketing-platform",
    component: _3b6832c4,
    name: "products-tools-google-marketing-platform"
  }, {
    path: "/services-solutions/conversion-optimization",
    component: _0770111a,
    name: "services-solutions-conversion-optimization"
  }, {
    path: "/services-solutions/digital-analytics",
    component: _42a04380,
    name: "services-solutions-digital-analytics"
  }, {
    path: "/products-tools/google-cloud/:slug",
    component: _0f35953c,
    name: "products-tools-google-cloud-slug"
  }, {
    path: "/products-tools/google-marketing-platform/:slug",
    component: _e85bea02,
    name: "products-tools-google-marketing-platform-slug"
  }, {
    path: "/services-solutions/conversion-optimization/:slug",
    component: _5831506e,
    name: "services-solutions-conversion-optimization-slug"
  }, {
    path: "/services-solutions/digital-analytics/:slug",
    component: _f45cc28a,
    name: "services-solutions-digital-analytics-slug"
  }, {
    path: "/cases/:slug",
    component: _23585c21,
    name: "cases-slug"
  }, {
    path: "/events/:slug",
    component: _16469567,
    name: "events-slug"
  }, {
    path: "/insights/:slug",
    component: _0d92fa29,
    name: "insights-slug"
  }, {
    path: "/services-solutions/:slug",
    component: _d5e1988e,
    name: "services-solutions-slug"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
